import accounting from "accounting";

const moneyFormat = function (string: string | number, currencySymbol: string) {
  const defaultSymbol = "฿";
  const number =
    typeof string === "string" ? string.replace(/[^\d.-]/g, "") : string;
  const option = {
    symbol: currencySymbol || defaultSymbol,
    decimal: ".",
    thousand: ",",
    precision: 0,
    format: "%s%v",
  };
  return accounting.formatMoney(number, option);
};
export default moneyFormat;
